var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AffairList" },
    [
      _vm._l(_vm.affairList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "affair-list-item",
            on: {
              click: function ($event) {
                return _vm.getCbApproveSteps(item)
              },
            },
          },
          [
            _c("div", { staticClass: "list-left" }, [
              _c("div", { staticClass: "list-title flex-align-center" }, [
                _vm._v(_vm._s(item.flowName)),
                _c(
                  "span",
                  {
                    staticClass: "status-label",
                    style:
                      "background-color: " +
                      item.bgColor +
                      ";color: " +
                      item.color +
                      ";",
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
              ]),
              _c(
                "div",
                { staticClass: "list-box" },
                _vm._l(item.summaries, function (v, i) {
                  return _c("span", { key: i, staticClass: "list-date" }, [
                    _c("span", [_vm._v(_vm._s(v.label))]),
                    _c("span", [_vm._v(_vm._s(v.value))]),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "list-right" }, [
              _c(
                "div",
                { staticClass: "list-info" },
                [
                  _c("CbAvater", {
                    attrs: {
                      "on-job-id": item.initiatorId,
                      "avater-name": item.initiatorName,
                      size: "24",
                      "default-name-length": 1,
                      "show-user-name": false,
                    },
                  }),
                  item.initiatorName !== undefined &&
                  item.initiatorName.length < 4
                    ? _c("span", { staticClass: "user-name" }, [
                        _vm._v(_vm._s(item.initiatorName)),
                      ])
                    : _c(
                        "a-tooltip",
                        { staticClass: "user-name" },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(item.initiatorName) + " "),
                          ]),
                          item.initiatorName !== undefined
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(item.initiatorName.slice(0, 3)) + "..."
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                ],
                1
              ),
              _c("div", { staticClass: "list-time" }, [
                _vm._v(_vm._s(item.endTime)),
              ]),
            ]),
          ]
        )
      }),
      _c("CbApproveSteps", {
        attrs: {
          "flow-id": _vm.flowId,
          "apply-status": _vm.applyStatus,
          "apply-status-list": _vm.statusNameList,
          "is-home": "",
        },
        scopedSlots: _vm._u([
          {
            key: "approveButton",
            fn: function (ref) {
              var participant = ref.participant
              return [
                _vm.isRole(participant.role)
                  ? _c(
                      "div",
                      { staticClass: "approve-button" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.addSend(
                                  participant.flowRecordParticipantId
                                )
                              },
                            },
                          },
                          [_vm._v("抄送")]
                        ),
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.transfer(
                                  participant.flowRecordParticipantId
                                )
                              },
                            },
                          },
                          [_vm._v("转交")]
                        ),
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.refuse(participant)
                              },
                            },
                          },
                          [_vm._v("拒绝")]
                        ),
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.agree(participant)
                              },
                            },
                          },
                          [_vm._v("同意")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.stepsVisible,
          callback: function ($$v) {
            _vm.stepsVisible = $$v
          },
          expression: "stepsVisible",
        },
      }),
      _c("TransferModal", {
        ref: "transferModal",
        on: {
          refresh: function ($event) {
            return _vm.$emit("refreshList")
          },
        },
      }),
      _c("ApprovalModal", {
        ref: "approvalModal",
        on: {
          refresh: function ($event) {
            return _vm.$emit("refreshList")
          },
        },
      }),
      _c("AddSendModal", { ref: "addSendModal" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }